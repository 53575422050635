import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay, concatMap } from 'rxjs/operators';

import { ProfileService } from '@shared/auth';

@Injectable({
  providedIn: 'root',
})
export class HourApiService {
  private http = inject(HttpClient);
  private profileService = inject(ProfileService);

  private baseUri = null;
  private lastPost$: Observable<any>;

  constructor() {
    this.profileService.apiUrl$.subscribe(apiUrl => {
      this.baseUri = `${apiUrl}/api/projecttaskhours`;
    });
  }

  getByProjectNumber(projectNumber): Observable<any> {
    const url = `${this.baseUri}/${projectNumber}`;
    return this.http.get(url)
      .pipe(
        map((hours: any[]) => hours.map(h => {
          h.startTime = this.convertToDate(h.startTime);
          h.endTime = this.convertToDate(h.endTime);
          h.synced = true;
          return h;
        })),
      );
  }

  /*
    Wartet bis vorheriger post fertig ist und startet erst danach!
    Problem Tabelle ProjectTaskHour hat Trigger für Projektberechnungen
    TODO: Nach Offline wenn wieder Online werden alle shareReplay-Post-Requests doch noch ausgeführt
  */
  postSequential(hours): Observable<any> {
    if (this.lastPost$) {
      this.lastPost$ = this.lastPost$
        .pipe(
          // tap(_ => console.log('start next post')),
          concatMap(_ => this.post(hours)), // concatMap does not subscribe to the next observable until the previous completes
          shareReplay(),
        );
    } else {
      this.lastPost$ = this.post(hours).pipe(shareReplay()); // shareReplay damit http.post nicht doppelt ausgeführt wird
    }
    return this.lastPost$;
  }

  private post(hours): Observable<any> {
    const url = `${this.baseUri}/`;
    return this.http.post(url, hours);
  }

  private convertToDate(date) {
    return date
      ? new Date(date)
      : null;
  }

}
